<template>
  <div class="contact-uscomop">
    <div class="header">
      <h4>
        <img src="@/assets/photos/Group.svg" alt="" />
        {{ parentContact.header }}
      </h4>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <div class="content">
          <p>{{ parentContact.description }}</p>
          <div class="header">
            <h4>
              <img src="@/assets/photos/Group.svg" alt="" /> اترك رسالتك هنا
            </h4>
          </div>
          <div class="form">
            <form @submit.prevent="send">
              <div class="input-name">
                <i class="fa-solid fa-user"></i>
                <input v-model="name" type="text" placeholder="الاسم" />
              </div>
              <span
                class="error-feedback"
                v-if="submitted && !v$.name.$pending && !v$.name.$model"
              >
                {{ errorMessages.name }}
              </span>
              <div class="all-inter">
                <div class="input-email">
                  <i class="fa-solid fa-envelope"></i>
                  <input
                    v-model="email"
                    type="email"
                    placeholder="البريد الالكتروني"
                  />
                </div>

                <div class="input-number">
                  <i class="fa-solid fa-phone"></i>
                  <input v-model="phone" type="text" placeholder="رقم الهاتف" />
                </div>
              </div>
              <div class="eror-input">
                <span
                  class="error-feedback"
                  v-if="submitted && !v$.email.$pending && !v$.email.$model"
                >
                  {{ errorMessages.email }}
                </span>
                <span
                  class="error-feedback"
                  v-if="submitted && !v$.phone.$pending && !v$.phone.$model"
                >
                  {{ errorMessages.phone }}
                </span>
              </div>
              <div class="input-message">
                <textarea
                  v-model="message"
                  cols="30"
                  rows="10"
                  placeholder="الرسالة"
                ></textarea>
              </div>
              <span
                class="error-feedback"
                v-if="submitted && !v$.message.$pending && !v$.message.$model"
              >
                {{ errorMessages.message }}
              </span>
              <button class="send" type="submit">ارسال</button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <div id="map" style="height: 60vh"></div>
        <div class="information">
          <div class="phone-number">
            <i class="fa-solid fa-phone"></i>
            <div class="number-frist">
              <h4>رقم الهاتف</h4>
              <p>{{ parentSitting.phone }}</p>
            </div>
          </div>
          <div class="email-adress">
            <i class="fa-solid fa-envelope"></i>
            <div class="email-frist">
              <h4>البريد الألكترونى</h4>
              <p>{{ parentSitting.email }}</p>
            </div>
          </div>
          <div class="location">
            <i class="fa-solid fa-location-dot"></i>
            <div class="location-frist">
              <h4>موقعنا</h4>
              <p>{{ parentSitting.location }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "ContactUsComponent",
  props: {
    parentContact: {
      type: Object,
      required: true,
    },
    parentSitting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      name: "",
      email: "",
      phone: "",
      message: "",
      submitted: false, // Track if the form has been submitted
    };
  },
  validations() {
    return {
      name: { required },
      email: { required, email },
      phone: { required },
      message: { required },
    };
  },
  computed: {
    errorMessages() {
      return {
        name: "هذا الحقل مطلوب.",
        email: "يرجى إدخال بريد إلكتروني صحيح.",
        phone: "هذا الحقل مطلوب.",
        message: "يرجى إدخال رسالة.",
      };
    },
  },
  watch: {
    parentSitting() {
      if (this.parentSitting.lat && this.parentSitting.lon) {
        const map = L.map("map").setView(
          [this.parentSitting.lat, this.parentSitting.lon],
          6
        );
        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
          map
        );
        L.marker([this.parentSitting.lat, this.parentSitting.lon])
          .addTo(map)
          .bindPopup(
            `Latitude: ${this.parentSitting.lat}<br>Longitude: ${this.parentSitting.lon}`
          )
          .openPopup();
      } else {
        console.error("Latitude and Longitude are not provided");
      }
    },
  },
  mounted() {
    this.v$.$reset();
    if (this.parentSitting.lat && this.parentSitting.lon) {
      const map = L.map("map").setView(
        [this.parentSitting.lat, this.parentSitting.lon],
        6
      );
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
        map
      );
      L.marker([this.parentSitting.lat, this.parentSitting.lon])
        .addTo(map)
        .bindPopup(
          `Latitude: ${this.parentSitting.lat}<br>Longitude: ${this.parentSitting.lon}`
        )
        .openPopup();
    } else {
      console.error("Latitude and Longitude are not provided");
    }
  },
  methods: {
    async send() {
      this.submitted = true; // Set the form as submitted
      this.v$.$touch(); // Trigger validation for all fields

      if (this.v$.$invalid) {
        Swal.fire({
          icon: "error",
          title: "خطأ في التحقق",
          text: "يرجى ملء جميع الحقول المطلوبة بشكل صحيح.",
        });
        return;
      }

      try {
        const response = await axios.post(
          "https://back.success.sa/public/api/post-contact_us",
          {
            name: this.name,
            email: this.email,
            phone: this.phone,
            message: this.message,
          }
        );

        Swal.fire({
          icon: "success",
          title: "نجاح!",
          text: response.data.message || "تم إرسال الرسالة بنجاح.", // Fallback message if response.data.message is not available
        });

        this.resetForm();
      } catch (error) {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "أوه...",
          text: "حدث خطأ ما! يرجى المحاولة مرة أخرى لاحقًا.",
        });
      }
    },
    resetForm() {
      this.name = "";
      this.email = "";
      this.phone = "";
      this.message = "";
      this.submitted = false; // Reset the form submission state
      this.v$.$reset(); // Reset validation state
    },
  },
};
</script>

<style lang="scss">
.error-feedback {
  color: red;
  margin-bottom: 1rem;
  font-family: "bold";
}
.eror-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
