<template>
  <div class="OurTrainingSolutions" id="OurTrainingSolutions">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
        <div class="all" v-for="(all, i) in parntsolutionsfeature" :key="i">
          <div class="right">
            <a href="https://sahel.success.sa" target="_blank">
              <div class="moauin">
                <h4>{{ all[0] }}</h4>
                <i class="fa-solid fa-arrow-left"></i>
              </div>
            </a>
            <a href="https://teacher.success.sa/" target="_blank">
              <div class="moauin">
                <h4>{{ all[1] }}</h4>
                <i class="fa-solid fa-arrow-left"></i>
              </div>
            </a>
          </div>
          <div class="center">
            <a href="https://step.success.sa/" target="_blank">
              <div class="moauin">
                <h4>{{ all[2] }}</h4>
                <i class="fa-solid fa-arrow-left"></i>
              </div>
            </a>
          </div>
          <div class="left">
            <router-link to="/">
              <div class="moauin">
                <h4>{{ all[3] }}</h4>
                <i class="fa-solid fa-arrow-left"></i>
              </div>
            </router-link>
            <router-link to="/">
              <div class="moauin">
                <h4>{{ all[4] }}</h4>
                <i class="fa-solid fa-arrow-left"></i>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
        <div class="text-training">
          <h4>
            <img src="@/assets/photos/Group.svg" alt="" />
            {{ parntsolutions.header }}
          </h4>
          <p>
            {{ parntsolutions.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OurTrainingSolutions",
  data() {
    return {};
  },
  props: {
    parntsolutions: {
      type: Array,
      required: true,
    },
    parntsolutionsfeature: {
      type: Array,
      required: true,
    },
  },
};
</script>
