<template>
  <div class="contact-us">
    <h4>
      <router-link to="/">الرئيسية</router-link>
      <i class="fa-solid fa-chevron-left"></i><span>تواصل معنا</span>
    </h4>
    <ContactUsComop
      :parentContact="parentContact"
      :parentSitting="parentSitting"
    />
  </div>
</template>
<script>
import ContactUsComop from "../components/Global/ContactUS/ContactUsComop.vue";
import axios from "axios";
export default {
  name: "ContactUs",
  components: {
    ContactUsComop,
  },
  data() {
    return {
      parentContact: [],
      parentSitting: [],
    };
  },
  async mounted() {
    try {
      const response = await axios.get(
        "https://back.success.sa/public/api/fetch-contact_us"
      );
      if (response.data.status == true) {
        this.parentContact = response.data.data.contact_us;
        this.parentSitting = response.data.data.settings;
        console.log(this.parentContact);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
};
</script>
<style lang="scss" scoped>
.contact-us {
  h4 {
    margin-top: 12rem;
  }
}
</style>
