<template>
  <div class="home_slider">
    <swiper
      :pagination="pagination"
      :direction="'vertical'"
      :slidesPerView="1"
      :spaceBetween="30"
      :mousewheel="true"
      :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
      }"
      :modules="modules"
      class="mySwiper"
    >
      <swiper-slide v-for="(slide, i) in parntsliderData.media" :key="i">
        <img :src="slide" alt="" />
      </swiper-slide>
      <div class="overtext">
        <p class="title">
          <img src="@/assets/photos/binoculars 1.svg" alt="" />
          {{ parntsliderData.title }}
        </p>
        <h4 class="subtitle">{{ parntsliderData.subtitle }}</h4>
        <h6 class="desc">
          {{ parntsliderData.description }}
        </h6>
        <!-- <a href="#OurTrainingSolutions">
          <button class="more">معرفة المزيد</button>
        </a> -->
      </div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";

import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";

export default {
  name: "HomeSlider",

  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination, Autoplay],
      pagination: {
        clickable: true,
      },
    };
  },
  data() {
    return {};
  },
  props: {
    parntsliderData: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss"></style>
