<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img src="@/assets/photos/اللوجو - نهائي (1) 1.png" alt="Logo" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link
              to="/"
              class="nav-link"
              aria-current="page"
              @click="closeNavbar"
            >
              الرئيسية
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link" @click="closeNavbar">
              من نحن
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/contact-us" class="nav-link" @click="closeNavbar">
              تواصل معنا
            </router-link>
          </li>
        </ul>
        <div class="login">
          <router-link to="/contact-us" class="nav-link" @click="closeNavbar">
            اتصل بنا
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";
export default {
  methods: {
    closeNavbar() {
      // Get the Bootstrap collapse instance and hide it
      const collapseElement = document.getElementById("navbarSupportedContent");
      const collapse = new bootstrap.Collapse(collapseElement, {
        toggle: false,
      });
      collapse.hide();
    },
  },
};
</script>

<style scoped>
/* Scoped styles for this component */
.navbar-brand img {
  max-height: 50px; /* Adjust as needed */
}
</style>
