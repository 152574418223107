<template>
  <div class="topnav">
    <div class="right-sec">
      <h4>
        <a href="mailto:{{ parentSitting.email }}" target="_blank">
          <i class="fa-solid fa-envelope"></i>{{ parentSitting.email }}
          <span>|</span>
        </a>
      </h4>
      <h4>
        <a :href="parentSitting.address" target="_blank">
          <i class="fa-solid fa-location-dot"></i>{{ parentSitting.location
          }}<span>|</span>
        </a>
      </h4>
      <h4>
        <a href="tel:{{ parentSitting.phone }}" target="_blank">
          <i class="fa-solid fa-phone"></i>{{ parentSitting.phone }}
        </a>
      </h4>
    </div>
    <div class="left-sec">
      <a :href="parentSitting.facebook" target="_blank">
        <i class="fa-brands fa-facebook-f"></i>
      </a>
      <a :href="parentSitting.linkedin" target="_blank">
        <i class="fa-brands fa-linkedin-in"></i>
      </a>
      <a :href="parentSitting.twitter" target="_blank">
        <i class="fa-brands fa-twitter"></i>
      </a>
      <a :href="parentSitting.youtube" target="_blank">
        <i class="fa-brands fa-youtube"></i>
      </a>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "TopNav",
  data() {
    return {
      parentSitting: [],
    };
  },
  async mounted() {
    try {
      const response = await axios.get(
        "https://back.success.sa/public/api/fetch-contact_us"
      );
      if (response.data.status == true) {
        this.parentSitting = response.data.data.settings;
        console.log(this.parentSitting);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
};
</script>
